.App {
  /* text-align: center; */
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  display:inline;
  margin: 10px;
}

.subject{
  width: 305px;
  /* margin: 10px 25px 10px 10px; */
  display: inline-block;
  margin-left: 10px;
}

.isDonetrue{
  text-decoration: line-through;
}

.listItem{
  border: 1px solid salmon;
  padding: 5px;
  width: 350px;
  display: flex;
}

.todoList{
  margin-bottom: 5%;
}

.taskDone{
  margin-top: 7%;
  margin-left: -13%;
}

.newItem{
  padding: 5px;
  width: 230px;
  margin-right: 5px;
}

.addItem{
  width: 45px;
  padding: 5px;
}

.pointer{
  cursor: pointer;
}

.menuList{
  padding: 10px !important;
  border-bottom: 2px solid blue;
  background-color: aquamarine;
}

.addItemDiv{
  margin-left: 10%;
  margin-top: 6.5%;
}

.about{
  margin-top: 7%;
  margin-left: -8.5%;
}

.loginDiv{
  background-color: seashell;
  height: 350px;
  width: 320px;
  border-radius: 5px;
  padding: 10px;
  color: black;
  align-items: center;
  text-align: center;
}

.login-label{
  margin-left: -32% !important;
}

.login-button{
  width: 80px;
  height: 25px;
}

.instruction{
  margin-top: 10%;
}

.link{
  text-decoration: underline;
  cursor: pointer;
  color: blue
}

.logout{
  float: right;
  margin-top: -1.8%;
  margin-right: 2%;
  width: 70px;
  height: 25px;
  font-weight: bold;
}

.marginTop{
  margin-top: -20%;
}

.marginTop2{
  margin-top: -10%;
}